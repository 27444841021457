import * as React from 'react';
import Layout from '../../components/layout';
import { Header } from '../../components/organisms/Header';
import NeedMore from '../../components/organisms/NeedMore';
import { graphql } from 'gatsby';
import PageContent from '../../components/organisms/PageContent';
import NewestNews from '../../components/organisms/NewestNews';
import DataboutTools from '../../components/organisms/DataboutTools';
import HarmonyContent from '../../components/organisms/HarmonyContent';
import SEO from '../../components/seo';
import { AboutType } from '../uslugi/transport';
import harmonyHeader from '../../images/harmony-header.svg';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel, WpPost } from '../../shared/wp-post-utils';
import { defaultLanguage } from '../../../site-config';

type HarmonyType = AboutType & {
  data: {
    parent: WpPost & {
      harmony: {
        title: string;
        subtitle: string;
        description: string;
      }
    }
  }
}

const Harmony: React.FC<HarmonyType> = ({ data, location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];
  const user = data.parent.author.node;
  const { content, harmony: { title, subtitle, description } } = data.parent;
  const SEOTitle = "Databout.Harmony";


  return (
    <Layout>
      <SEO title={SEOTitle} description={description} />
      <Header
        title={title}
        description={<>{description}</>}
        subTitle={subtitle}
        headerImage={harmonyHeader}
        headerImageWidth={700}
        isSignature={true}
        user={user}
        signatureAuthor={LDAPHeaderUser.name}
        signaturePosition={LDAPHeaderUser.title}
        signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
        signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
        signatureEmail={LDAPHeaderUser.mail}
        signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
        signatureShareLink={location.href}
        isArrowNavVisible={true}
        firstHeaderNavLink="tools"
        firstHeaderNavLinkName="Tools"
        SEOTitle={SEOTitle}
        SEODescription={description}
      />
      <PageContent>
        <HarmonyContent content={content} />
        {data.newestNews.nodes.length > 0 && language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)} />}
        <DataboutTools title={t('serviceAd.title')} subTitle={t('serviceAd.subTitle')} description={t('serviceAd.description')} link="/uslugi" />
        <NeedMore />
      </PageContent>
    </Layout>

  );
}

export default Harmony;

export const pageQuery = graphql`
query($language: String!) {
	parent: wpPage(slug: {in: ["harmony", "harmony-en"]}, language: {slug: {eq: $language}}) {
		    ...AuthorPage
        ...Harmony
	}
  newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "tools"}}}}}
    limit: 3
  ) {
      nodes {
        ...PageItems
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`





